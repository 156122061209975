<template>
  <v-hover v-slot="{hover}">
      <div class="pt-4" v-if="section.isHtml" >

        <v-row  no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height"  >

          <!-- Drag Icon-->
          <v-col style=" height: 100% !important;" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <v-sheet color="transparent" width="32" class="editor-section-drag">
              <v-icon v-if="hover"  style="cursor: grab"  :color="wsACCENT">
                mdi-drag-vertical
              </v-icon>
            </v-sheet>
          </v-col>
          <!-- Section Content -->
          <v-col style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">

            <!-- Image -->

            <ws-tip-tap
                v-model="section.text"
                @change="$emit('input', section)"
            />

          </v-col>
          <!-- Section Edit Buttons -->
          <v-col style=" height: 100% !important;" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <v-sheet width="32" color="transparent" class="pl-1 fill-height " >
              <ft-select
                  @input="$emit('edit',$event)"
                  :items="editActionsSelect">
                <ws-icon-button v-if="hover" small   />
              </ft-select>
            </v-sheet>
          </v-col>

        </v-row>

      </div>
  </v-hover>

</template>

<script>

import wsTipTap from "@/components/UI/tiptap/wsTipTap.vue";

export default {
  name: "editorSectionHtml",
  components : {
    wsTipTap,
  },
  props : {
    value : {
      type : Object
    },
    section : {
      type : Object,
      default() { return {} }
    },
    lang : {
      type : String,
      default : 'ua',
    },
    placeholder : {
      type : String
    }
  },
  computed : {
    editActionsSelect() {
      return [
        { text : this.$t('Duplicate') , value: 'duplicate' , icon : 'mdi-content-copy' },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ];
    },
  },
  methods : {
  }
}
</script>

<style scoped>
</style>